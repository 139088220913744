import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';

import { colors } from '@constants/colors';

import { CustomButtonPropsType } from './types';

const CustomButton: FC<CustomButtonPropsType> = ({
  onClick,
  fullWidth = false,
  label,
  variant = 'contained',
  isLoading,
  isDisabled,
  rightWidget,
  leftWidget,
  customClassname,
  isTimerActive,
  icon
}) => {
  const getBackgroundColor = () => {
    if (isDisabled) {
      return isTimerActive ? colors.white : colors.gray;
    }

    return colors.primaryBlue;
  };

  const getColor = () => {
    return isTimerActive ? colors.gray : colors.white;
  };

  // eslint-disable-next-line no-undef
  const buttonStyle: React.CSSProperties = {
    backgroundColor: getBackgroundColor(),
    color: getColor()
  };
  const oulinedButtonStyle = {
    backgroundColor: 'transparent',
    color: isDisabled ? colors.gray11 : colors.primaryBlue
  };

  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      className={`relative group 1.25xl:h-[42px] h-[30px] ${customClassname} hover:justify-center`}
      disabled={isDisabled}
      style={variant === 'contained' ? buttonStyle : oulinedButtonStyle}
      startIcon={
        icon && (
          <span
            className={
              'group-hover:mx-auto transition-transform duration-1000 delay-300'
            }>
            {icon}
          </span>
        )
      }>
      {isLoading ? (
        <CircularProgress size="1rem" color="inherit" />
      ) : (
        <>
          {leftWidget}
          {label}
          {rightWidget}
        </>
      )}
    </Button>
  );
};

export default CustomButton;
