import { FC } from 'react';

import { Chip } from '@mui/material';
import { colors } from '@constants/colors';
import { hexToRgba } from '@utils/common';

import { ColorChipProps } from './types';

const ColorChip: FC<ColorChipProps> = ({
  eachEntry,
  colorMapping,
  labelValue
}) => {
  const entryColor = colorMapping[String(eachEntry)];

  const { backgroundColor, textColor } = entryColor
    ? {
        backgroundColor: hexToRgba(entryColor.backgroundColor, 0.2),
        textColor: entryColor.textColor
      }
    : {
        backgroundColor: hexToRgba(colors.gray, 0.2),
        textColor: colors.gray11
      };

  return (
    <Chip
      label={labelValue}
      size="medium"
      variant="filled"
      style={{
        color: textColor,
        backgroundColor
      }}
    />
  );
};

export default ColorChip;
